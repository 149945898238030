import { default as activityCJvAa3eS7WMeta } from "/home/ploi/my.abcreche.com/pages/activity.vue?macro=true";
import { default as loginXvJN5MlNBQMeta } from "/home/ploi/my.abcreche.com/pages/Auth/login.vue?macro=true";
import { default as logoutbrQs76EcrwMeta } from "/home/ploi/my.abcreche.com/pages/Auth/logout.vue?macro=true";
import { default as babyLLGUBisSBBMeta } from "/home/ploi/my.abcreche.com/pages/baby.vue?macro=true";
import { default as calendarS3pVvrbkQ6Meta } from "/home/ploi/my.abcreche.com/pages/calendar.vue?macro=true";
import { default as daysoff3Ic1nbKKbeMeta } from "/home/ploi/my.abcreche.com/pages/creche/daysoff.vue?macro=true";
import { default as documentsKJi929MI7yMeta } from "/home/ploi/my.abcreche.com/pages/creche/documents.vue?macro=true";
import { default as eventsCCal5PwzvPMeta } from "/home/ploi/my.abcreche.com/pages/creche/events.vue?macro=true";
import { default as socialLpgNwJoQq8Meta } from "/home/ploi/my.abcreche.com/pages/creche/social.vue?macro=true";
import { default as documents9EXV5Gzhu1Meta } from "/home/ploi/my.abcreche.com/pages/documents.vue?macro=true";
import { default as indexPSXtpb1yTnMeta } from "/home/ploi/my.abcreche.com/pages/index.vue?macro=true";
import { default as indexvIdcl90su1Meta } from "/home/ploi/my.abcreche.com/pages/Invoices/index.vue?macro=true";
export default [
  {
    name: "activity",
    path: "/activity",
    component: () => import("/home/ploi/my.abcreche.com/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: "Auth-login",
    path: "/Auth/login",
    meta: loginXvJN5MlNBQMeta || {},
    component: () => import("/home/ploi/my.abcreche.com/pages/Auth/login.vue").then(m => m.default || m)
  },
  {
    name: "Auth-logout",
    path: "/Auth/logout",
    meta: logoutbrQs76EcrwMeta || {},
    component: () => import("/home/ploi/my.abcreche.com/pages/Auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "baby",
    path: "/baby",
    component: () => import("/home/ploi/my.abcreche.com/pages/baby.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/home/ploi/my.abcreche.com/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "creche-daysoff",
    path: "/creche/daysoff",
    component: () => import("/home/ploi/my.abcreche.com/pages/creche/daysoff.vue").then(m => m.default || m)
  },
  {
    name: "creche-documents",
    path: "/creche/documents",
    component: () => import("/home/ploi/my.abcreche.com/pages/creche/documents.vue").then(m => m.default || m)
  },
  {
    name: "creche-events",
    path: "/creche/events",
    component: () => import("/home/ploi/my.abcreche.com/pages/creche/events.vue").then(m => m.default || m)
  },
  {
    name: "creche-social",
    path: "/creche/social",
    component: () => import("/home/ploi/my.abcreche.com/pages/creche/social.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/home/ploi/my.abcreche.com/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ploi/my.abcreche.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Invoices",
    path: "/Invoices",
    component: () => import("/home/ploi/my.abcreche.com/pages/Invoices/index.vue").then(m => m.default || m)
  }
]